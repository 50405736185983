<template>
	<div>
		<br />
		<div class="col-sm-12 text-right">
			<h1>Account</h1>
		</div>
		<br />

		<div class="d-flex flex-wrap">
			<div v-if="this.$auth.can('account', 'ver_organizacion')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/account_organizaciones.png" alt="Facturas" width="80px" />
						</div>
						<h4 class="card-title">Organizaciones</h4>
						<p class="card-subtitle text-muted">Administración de organizaciones</p>
						<router-link :to="{name: 'account_organizaciones'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
			<div v-if="this.$auth.can('account', 'ver_aplicacion')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/account_aplicaciones.png" alt="Facturas" width="80px" />
						</div>
						<h4 class="card-title">Aplicaciones</h4>
						<p class="card-subtitle text-muted">Administración de aplicaciones</p>
						<router-link :to="{name: 'account_aplicaciones'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
			<div v-if="this.$auth.can('account', 'ver_roles')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/account_roles.png" alt="Facturas" width="80px" />
						</div>
						<h4 class="card-title">Roles</h4>
						<p class="card-subtitle text-muted">Administración de roles</p>
						<router-link :to="{name: 'account_roles'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
			<div v-if="this.$auth.can('account', 'ver_usuario')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/account_usuarios.png" alt="Facturas" width="80px" />
						</div>
						<h4 class="card-title">Usuarios</h4>
						<p class="card-subtitle text-muted">Administración de usuarios</p>
						<router-link :to="{name: 'account_usuarios'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
			<div v-if="this.$auth.can('account', 'listar_tokens')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/account_token.png" alt="Facturas" width="80px" />
						</div>
						<h4 class="card-title">Tokens para aplicaciones</h4>
						<p class="card-subtitle text-muted">Administración de tokens para aplicaciones</p>
						<router-link :to="{name: 'account_tokens'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
		</div>
		
		<div v-if="this.$auth.can('account', 'ver_sesion')" class="row">
			<br />
			<div class="col-sm-12 text-right">
				<h1>Información general</h1>
			</div>
			<br />
		</div>

		<div v-if="this.$auth.can('account', 'ver_sesion')" class="d-flex flex-wrap">
			<div class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/account_sesiones.png" alt="Facturas" width="80px" />
						</div>
						<h4 class="card-title">Sesiones</h4>
						<p class="card-subtitle text-muted">Administración de sesiones de usuarios</p>
						<router-link :to="{name: 'account_sesiones'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		mounted: function() {
			let menu = {title: 'Account'};

			if (this.$auth.can('account', 'ver_organizacion'))
				menu['account_organizaciones'] = 'Organizaciones';

			if (this.$auth.can('account', 'ver_aplicacion'))
				menu['account_aplicaciones'] = 'Aplicaciones';

			if (this.$auth.can('account', 'ver_roles'))
				menu['account_roles'] = 'Roles';

			if (this.$auth.can('account', 'ver_usuario'))
				menu['account_usuarios'] = 'Usuarios';

			if (this.$auth.can('account', 'listar_tokens'))
				menu['account_tokens'] = 'Token';

			if (this.$auth.can('account', 'ver_sesion'))
				menu['account_sesiones'] = 'Sesiones';

			this.$store.commit('setMenu',[menu]);
		}
	}
</script>